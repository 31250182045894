<template>
	<a-config-provider :locale="zh_CN">
		<a-spin :spinning="loading">
			<div class="modal-header">
				<div class="modal-title">
					<span>{{ l('批量调班') }}</span>
				</div>
			</div>
			<div class="my-md">
				<a-row>
					<a-col :span="4">
						<q-label :label="l('公司')"> </q-label>
					</a-col>
					<a-col span="20">
						<a-tree-select
							class="left"
							@change="handleChange"
							allowClear
							:placeholder="l('Company')"
							style="width: 90%"
							:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
							:tree-data="companyTreeList"
							tree-default-expand-all
							v-model="comPanyId"
						/>
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label :label="l('部门')"> </q-label>
					</a-col>
					<a-col span="20">
						<a-tree-select
							v-model="departmentId"
							allowClear
							style="width: 90%"
							show-search
							:filterTreeNode="searchTree"
							:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
							:tree-data="DepTreeData"
							:placeholder="l('Department')"
							tree-default-expand-all
							@select="SelectDepartmentTree"
							@change="getData"
						>
						</a-tree-select>
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label :label="l('考勤组')"> </q-label>
					</a-col>
					<a-col :span="20">
						<a-select
							show-search
							allowClear
							:placeholder="l('考勤组')"
							option-filter-prop="children"
							style="width: 90%"
							v-model="AttGroupId"
						>
							<a-select-option v-for="item in AttGroupList" :key="item.id">
								{{ item.name }}
							</a-select-option>
						</a-select>
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label :label="l('人员')"> </q-label>
					</a-col>
					<a-col :span="20">
						<div class="CheckUser" @click="checkUser">
							<a-tag color="#2db7f5" v-for="item in UserList" :key="item.userId" style="cursor: pointer;">
								{{ item.realName }}
							</a-tag>
						</div>
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="4">
						<q-label required :label="l('调班日期')"> </q-label>
					</a-col>
					<a-col :span="20">
						<a-col :span="11">
							<a-date-picker v-model="startTime" style="width: 100%" />
						</a-col>
						<a-col :span="2" style="margin: auto;line-height:3;text-align: center">
							<a-icon type="swap" style="font-size: 16px;" />
						</a-col>
						<a-col :span="11">
							<a-date-picker v-model="endTime" style="width: 100%" />
						</a-col>
					</a-col>
				</a-row>
			</div>
			<!-- 按钮 -->
			<div class="modal-footer">
				<a-button @click="close" type="button">
					<a-icon type="close-circle" />
					{{ l('Cancel') }}
				</a-button>
				<a-button :type="'primary'" @click="save">
					<a-icon type="save" />
					{{ l('Save') }}
				</a-button>
			</div>
		</a-spin>
	</a-config-provider>
</template>

<script>
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';
import ModalComponentBase from '../../../../shared/component-base/modal-component-base';
import ModalHelper from '../../../../shared/helpers/modal/modal-helper';
import SelectUserTag from '@/components/select-user-tag/select-user-tag.vue';
import {
	AttendanceGroupServiceProxy,
	AttendancePeopleEditDto,
	CompanyServiceProxy,
	DepartmentServiceProxy,
	UserShiftPlanChangeInput,
	UserShiftPlanServiceServiceProxy,
} from '../../../../shared/service-proxies';
import DepTreeUntils from '../../../organization/department/dep-tree-untils/dep-tree-untils';
import _ from 'lodash';

export default {
	name: 'shift-chanage',
	mixins: [ModalComponentBase, SelectUserTag],
	data() {
		return {
			zh_CN,
			UserList: [],
			companyTreeList: [],
			comPanyId: undefined,
			departmentId: undefined,
			//部门树
			DepTreeData: [],
			DepTree: [],
			companyList: [],
			AttGroupId: undefined,
			AttGroupList: [],
			startTime: undefined,
			endTime: undefined,
		};
	},
	created() {
		this.fullData();
		this._DepartmentServiceProxy = new DepartmentServiceProxy(this.$apiUrl, this.$api);
		this._CompanyConfigServiceProxy = new CompanyServiceProxy(this.$apiUrl, this.$api);
		this._attendanceGroupAppService = new AttendanceGroupServiceProxy(this.$apiUrl, this.$api);
		this._userShiftPlanServiceServiceProxy = new UserShiftPlanServiceServiceProxy(this.$apiUrl, this.$api);
	},
	mounted() {
		this.companyTreeInit();
		this.getAttGroupList();
	},
	methods: {
		/**
		 * 选择人员
		 */
		checkUser() {
			let list = [];
			if (this.UserList.length > 0) {
				list = this.UserList.map((item) => {
					return { id: item.id, realName: item.realName };
				});
			}
			ModalHelper.create(
				SelectUserTag,
				{
					_selectedRows: list,
					_selectedRowKeys: _.map(list, 'id'),
					isMultiple: true,
				},
				{
					width: '900px',
					closable: false,
				}
			).subscribe((res) => {
				if (res) {
					this.UserList = res;
					console.log(res);
				}
			});
		},
		searchTree(inputValue, treeNode) {
			return treeNode.data.props.title.includes(inputValue);
		},
		companyTreeInit() {
			this._CompanyConfigServiceProxy
				.getPaged(undefined, '', 100, 0)
				.finally(() => {
					this.spinning = false;
				})
				.then((res) => {
					this.companyList = res.items;
					this.companyTreeList = [];
					this.companyList.forEach((item) => {
						if (item.parentId == 0) {
							var obj = {
								key: item.id,
								value: item.id,
								title: item.fullName,
							};
							this.companyTreeList.push(obj);
						}
					});
					this.initCompanyTree(this.companyTreeList);
					// if (this.companyTreeList.length > 0&&!this.comPanyId) {
					//     this.handleChange(this.companyTreeList[0].key);
					// }
				});
		},
		/**
		 * 初始化树形结构数据格式
		 */
		initCompanyTree(list) {
			list.forEach((v) => {
				//遍历父级数据，加载子集=
				v.children = []; //创建空的子集
				this.companyList.forEach((m) => {
					if (m.parentId == v.value) {
						//根据主键查子集数据
						var obj = {
							key: m.id,
							value: m.id,
							title: m.fullName,
						};
						v.children.push(obj); //放进父级的集合中
						this.initCompanyTree(v.children); //递归查询子集的集合
					}
				});
				if (v.children.length == 0) {
					//校验如何数据没有子集，删除子集参数
					delete v.children;
				}
			});
		},
		handleChange(value) {
			this.comPanyId = value;
			this.departmentId = undefined;
			this.getDepTreeData();
		},
		/**
		 * 获取部门
		 */
		getDepTreeData() {
			this.DepTreeData = [];
			this._DepartmentServiceProxy
				.getDepTreeData(this.comPanyId)
				.then((res) => {
					this.DepTree = DepTreeUntils.getInstance().InitSelectData(res);
					this.DepTree.forEach((item) => {
						if (!item.parentId) {
							this.DepTreeData.push(item);
						}
					});
					this.DepTreeData = DepTreeUntils.getInstance().ArrToTree(this.DepTree, this.DepTreeData, false);
				})
				.finally(() => {
					this.loading = false;
				});
		},
		/**
		 * 选择部门
		 */
		SelectDepartmentTree(e) {
			this.departmentId = e;
		},
		/**
		 * 获取考勤组集合
		 */
		getAttGroupList() {
			this._attendanceGroupAppService
				.getAttGroupList()
				.finally(() => {})
				.then((res) => {
					this.AttGroupList = res;
				});
		},
		save() {
			if (!this.departmentId && !this.comPanyId && !this.AttGroupId && this.UserList.length <= 0) {
				return abp.message.warn('公司、部门、考勤组、人员至少选择一项');
			}
			if (!this.startTime || !this.endTime) {
				return abp.message.warn('调班日期不能为空');
			}
			let input = new UserShiftPlanChangeInput();
			input.companyId = this.comPanyId;
			input.departmentId = this.departmentId;
			input.attGroupId = this.AttGroupId;
			input.empIds = _.map(this.UserList, 'id');
			input.startTime = this.startTime;
			input.endTime = this.endTime;
			this.loading = true;
			this._userShiftPlanServiceServiceProxy
				.batchUserShiftPlanChange(input)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.$notification['success']({
						message: this.l('保存成功'),
					});
					this.success(true);
				});
		},
	},
};
</script>

<style scoped>
.CheckUser {
	border: 1px solid #d9d9d9;
	min-height: 35px;
	width: 90%;
	border-radius: 5px;
	display: flex;
	align-items: center;
	padding-left: 5px;
	line-height: 35px;
	overflow-x: auto;
	cursor: pointer;
}
.CheckUser::-webkit-scrollbar {
	/*滚动条整体样式*/
	width: 1px; /*高宽分别对应横竖滚动条的尺寸*/
	height: 6px;
}

.CheckUser::-webkit-scrollbar-thumb {
	border-radius: 10px;
	box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
	background: #808080;
}

.CheckUser::-webkit-scrollbar-track {
	/*滚动条里面轨道*/
	box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
	border-radius: 10px;
	background: #ededed;
}
</style>
