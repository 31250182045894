<template>
    <a-config-provider :locale="zh_CN">
        <a-spin :spinning="spinning">
            <a-card class="paddingtop">
                <div class="my-md" style="line-height: 0">
                    <!--                    筛选条件-->
                    <a-row style="line-height: 0" type="flex" :gutter="10">
                        <a-col :span="3">
                            <a-month-picker placeholder="选择月份"
                                            v-model="date"
                                            :allowClear="false"
                                            style="width: 100%"
                                            @change="monthChange">
                            </a-month-picker>
                        </a-col>
                        <a-col :span="4">
                            <a-tree-select class="left"
                                           @change="handleChange"
                                           :placeholder="l('Company')"
                                           style="width: 100%"
                                           :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                                           :tree-data="companyTreeList"
                                           tree-default-expand-all
                                           v-model="comPanyId"
                            />
                        </a-col>
                        <a-col :span="4">
                            <a-tree-select
                                    v-model="departmentId"
                                    allowClear
                                    style="width: 100%"
                                    show-search
                                    :filterTreeNode="searchTree"
                                    :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                                    :tree-data="DepTreeData"
                                    :placeholder="l('Department')"
                                    tree-default-expand-all
                                    @select="SelectDepartmentTree"
                                    @change="getData">
                            </a-tree-select>
                        </a-col>
                        <a-col :span="4">
                            <a-select
                                    show-search
                                    allowClear
                                    :placeholder="l('考勤组')"
                                    option-filter-prop="children"
                                    style="width: 100%"
                                    v-model="AttGroupId"
                                    @change="getData"
                            >
                                <a-select-option v-for="(item,index) in AttGroupList" :key="item.id">
                                    {{item.name}}
                                </a-select-option>
                            </a-select>
                        </a-col>
                        <a-col :span="5" style="display: flex;align-items: center;height: 31px">
                            <span>
                                <a-switch checked-children="未排班" un-checked-children="未排班" @change="getData"
                                          v-model="Unshift"/>
                            </span>
                            <span style="padding-left: 5px">
                                <a-switch checked-children="未分组" un-checked-children="未分组" @change="getData"
                                          v-model="unCheckEmp"/>
                            </span>
                        </a-col>
                        <a-col :span="4" style="text-align: right">
                            <a-form-item>
                                <a-input-search
                                        style="max-width: 200px"
                                        name="filterText"
                                        :placeholder="l('SearchWithThreeDot')"
                                        @search="getData"
                                        enterButton
                                        v-model="filterText"
                                />
                            </a-form-item>
                        </a-col>
                    </a-row>
                    <!--                    出勤周期提示-->
                    <a-row style="margin-top:-20px ">
                        <p style="font-size: 10px;color:#BEBEBE ">考勤周期:{{startTime}}~{{endTime}}</p>
                    </a-row>
                </div>
                <div class="d-flex align-items-center">
                    <a-button type="primary" @click="Export" v-if="isGranted('emp_attendance_plan_excel')">
                        <a-icon type="import" />
                        导入排班
                    </a-button>
                    <a-button type="primary" @click="shiftChange" v-if="isGranted('emp_attendance_shift_change')">
                        <a-icon type="edit" />
                        批量调班
                    </a-button>
                    <!--                    <div class="my-md">-->

                    <!--                    排班说明-->
                    <div style="margin-left: 20px;">
                        排班说明:
                        <a-tag color="#108ee9">
                            已排班
                        </a-tag>
                        <a-tag color="#87d068">
                            休息
                        </a-tag>
                        <a-tag color="pink">
                            法定节假日
                        </a-tag>
                        <a-tag>
                            未排班
                        </a-tag>
                    </div>

                    <!--                    </div>-->

                </div>
                <div class="my-md">

                    <div class="d-flex hide-scroll" style="position: sticky;top: -10px;overflow: auto;z-index: 100;"
                         ref="tableHead">
                        <div style="min-width: 300px;position: sticky;left: 0">

                            <div class="left-header right-border top-border">
                                <!--                                <div class="bottom-border left-border ">部门</div>-->

                                <div class="bottom-border left-border">工号</div>
                                <div class=" bottom-border left-border">姓名</div>
                                <div class="bottom-border left-border">入职日期</div>
                                <div class=" bottom-border left-border">离职日期</div>
                            </div>
                        </div>
                        <!--                            日期表头-->
                        <div style="flex: 1;">
                            <div class="right-header top-border">
                                <div class="bottom-border right-border"
                                     style="display: flex;flex-direction: column;max-width: 50px"
                                     v-for="(item,index) in dayheaderList" :key="index"
                                >
                                    <div class="header-column-text" :style="{color:item.color}" :title="item.title">
                                        <div>
                                            {{item.day}}
                                        </div>
                                        <div>
                                            {{item.week}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <!--                数据-->
                    <div class="Att-Table" :style="{'overflow-x':UserShiftPlanList.length>0?'auto':'hidden',}"
                         ref="tableBody" @scroll="scrollTableBody">
                        <div class="data-left">
                            <!--                            <div class="left-header right-border top-border">-->
                            <!--                                <div class=" bottom-border left-border">姓名</div>-->
                            <!--                                <div class="bottom-border left-border">工号</div>-->
                            <!--                            </div>-->
                            <div class="data-row bottom-border bg-white right-border"
                                 style="max-width: 300px"
                                 v-for="(item,index) in UserShiftPlanList">

                                <!--                                <div class="row-column left-border" @click="columnClick($event,index,1,item)" :title="item.departmentName">-->
                                <!--                                    <div class="column-text">{{item.departmentName}}</div>-->
                                <!--                                </div>-->
                                <div class="row-column left-border" @click="columnClick($event,index,1,item)"
                                     :title="item.jobNumber">
                                    <div class="column-text">
                                        <div class="ellipse">
                                            {{item.jobNumber}}
                                        </div>
                                    </div>
                                </div>
                                <div class="row-column left-border" @click="columnClick($event,index,1,item)"
                                     :title="item.userName">
                                    <div class="column-text">
                                        <div class="ellipse">
                                            {{item.userName}}
                                        </div>
                                    </div>
                                </div>
                                <div class="row-column left-border" @click="columnClick($event,index,1,item)"
                                     :title="item.hireDate">
                                    <div class="column-text">
                                        <div class="ellipse">
                                            {{item.hireDate}}
                                        </div>
                                    </div>
                                </div>
                                <div class="row-column left-border" @click="columnClick($event,index,1,item)"
                                     :title="item.termDate">
                                    <div class="column-text">
                                        <div class="ellipse">
                                            {{item.termDate}}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="data-right">
                            <!--                            日期表头-->
                            <!--                            <div class="right-header top-border">-->
                            <!--                                <div class="bottom-border right-border"-->
                            <!--                                     style="display: flex;flex-direction: column;max-width: 50px"-->
                            <!--                                     v-for="(item,index) in dayheaderList" :key="index"-->
                            <!--                                >-->
                            <!--                                    <div class="header-column-text" :style="{color:item.color}" :title="item.title">-->
                            <!--                                        <div>-->
                            <!--                                            {{item.day}}-->
                            <!--                                        </div>-->
                            <!--                                        <div>-->
                            <!--                                            {{item.week}}-->
                            <!--                                        </div>-->
                            <!--                                    </div>-->
                            <!--                                </div>-->
                            <!--                            </div>-->
                            <div class="data-row bottom-border color-white" v-for="(e,j) in UserShiftPlanList">
                                <div class="row-column right-border" style="max-width: 50px"
                                     v-for="(item,index) in UserShiftPlanList[j].planList" :key="index"
                                     @click="columnClick($event,j,0,item)">
                                    <div class="data-column-text " :style="{'background':item.bgcolor}"
                                         :title="item.title">
                                        <div class="ellipse">
                                            {{item.shiftName}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div v-if="UserShiftPlanList.length<=0">
                    <a-empty/>
                </div>
                <div>
                    <a-pagination
                            class="pagination"
                            v-model="pageNumber"
                            :page-size-options="pageSizeOptions"
                            :total="totalItems"
                            show-size-changer
                            @change="onChange"
                            :page-size="pageSize"
                            @showSizeChange="showSizeChange"
                            :showTotal="showTotalFun"
                    >
                    </a-pagination>
                </div>
                <!--                点击卡片-->
                <div class="plan-card" :style="style" v-if="isCrde"
                     style="position: absolute;background-color: white;z-index:10;min-height: 250px">
                    <a-tabs>
                        <a-tab-pane key="1" tab="按天排班" style="max-height: 300px;overflow-y: auto">
                            <a-row :gutter="[0,16]">
                                <a-col style="text-align: center;color: #9c9c9c;font-size: 10px">{{tabsTip}}</a-col>
                            </a-row>
                            <a-row v-for="(item,index) in shiftInfoCodeList" :gutter="[0,16]" :key="index">
                                <a-col :span="20" offset="2">
                                    <a-button block v-if="item.code=='Rest'" style="color:green;"
                                              @click="saveUserPlan(item,index)">
                                        {{item.name}}
                                    </a-button>
                                    <a-button block
                                              v-else-if="item.code=='Holiday'"
                                              style="color:deeppink;"
                                              @click="saveUserPlan(item,index)">
                                        {{item.name}}
                                    </a-button>
                                    <a-button block v-else-if="item.code=='Empty'" style="color:red;"
                                              @click="saveUserPlan(item,index)">
                                        {{item.name}}
                                    </a-button>

                                    <a-button block type="primary" v-else @click="saveUserPlan(item,index)">
                                        {{item.name}}
                                    </a-button>
                                </a-col>
                            </a-row>
                        </a-tab-pane>
                        <a-tab-pane key="2" tab="按周期排班" :disabled="tabDisabled">
                            <a-row :gutter="[0,16]">
                                <a-col style="text-align: center;color: #9c9c9c;font-size: 10px">从开始日期排至结束日期<br>(默认当前周期)
                                </a-col>
                            </a-row>
                            <a-row :gutter="[16,16]">
                                <a-col :span="20" offset="2">
                                    <a-date-picker style="width: 100%;" :placeholder="l('开始时间')"
                                                   :allowClear="false"
                                                   v-model="shiftStartTime"
                                                   :showToday="false"/>
                                </a-col>
                            </a-row>
                            <a-row :gutter="[16,16]">
                                <a-col :span="20" offset="2">
                                    <a-date-picker style="width: 100%;"
                                                   v-model="shiftEndTime"
                                                   :allowClear="false"
                                                   :placeholder="l('结束时间')" :showToday="false"/>
                                </a-col>
                            </a-row>
                            <a-row :gutter="[16,16]">
                                <a-col :span="20" offset="2">
                                    <a-select
                                            show-search
                                            :placeholder="l('开始班次')"
                                            option-filter-prop="children"
                                            style="width: 100%"
                                            v-model="shiftCodeIndex"
                                    >
                                        <a-select-option v-for="(item,index) in shiftInfoist" :key="index">
                                            {{item.name}}
                                        </a-select-option>
                                    </a-select>
                                </a-col>
                            </a-row>
                            <a-row :gutter="[16,16]">
                                <a-col :span="20" offset="1">
                                    <i style="color: red;padding-right: 2px">*</i>法定假日选项:
                                </a-col>
                            </a-row>
                            <a-row style="padding-bottom: 10px;text-align: center">
                                <a-col :span="24">
                                    <a-radio-group name="radioGroup" :default-value="1" v-model="shiftRule">
                                        <a-radio :value="1">
                                            跳过
                                        </a-radio>
                                        <a-radio :value="2">
                                            顺延
                                        </a-radio>
                                        <a-radio :value="3">
                                            覆盖
                                        </a-radio>
                                    </a-radio-group>
                                </a-col>
                            </a-row>
                            <a-row :gutter="[0,16]">
                                <a-col :span="20" offset="2">
                                    <a-button block type="primary" @click="saveUserShiftPlan">
                                        保存
                                    </a-button>
                                </a-col>
                            </a-row>
                        </a-tab-pane>
                    </a-tabs>
                </div>
            </a-card>
            <!--                排班卡片-->
            <div class="card-loading" :style="cardloadStyle" v-if="isCrde" @click="cardClick">

            </div>
        </a-spin>

    </a-config-provider>
</template>

<script>
    import {AppComponentBase} from "../../../shared/component-base";
    import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN'
    import {
        AttendanceGroupServiceProxy,
        CompanyServiceProxy,
        DepartmentServiceProxy,
        EnterpriseAttendCycleServiceProxy, ExportTemplateInput,
        OrganizationUnitServiceProxy, UserPlanImportInput,
        UserShiftPlanByCodeInput,
        UserShiftPlanByCycleInput,
        UserShiftPlanServiceServiceProxy
    } from "../../../shared/service-proxies";
    import moment from "moment";
    import deepClone from '../../../shared/utils/deepClone'
    import $ from 'jquery'
    import {AppConsts} from "../../../abpPro/AppConsts";
    import {abpService} from "../../../shared/abp";
    import {fileDownloadService} from "../../../shared/utils";
    import ImportExcelModel from './import-excel-model/import-excel-model'
    import ModalHelper from '../../../shared/helpers/modal/modal-helper'
    import ShiftChange from "./shift-change/shift-change"
    import DepTreeUntils from "../../organization/department/dep-tree-untils/dep-tree-untils";

    export default {
        name: "user-attendance-plan",
        mixins: [AppComponentBase],
        components: {ImportExcelModel,ShiftChange},
        data() {
            return {
                zh_CN,
                // 总数
                totalItems: 0,
                // 当前页码
                pageNumber: 1,
                //默认条数
                pageSize: 10,
                //开始索引
                startindex: 0,
                //结束索引
                endindex: 10,
                // 共多少页
                totalPages: 1,
                // 显示条数
                pageSizeOptions: ["10", "20", "30", "40", "50"],
                spinning: false,
                AttGroupList: [],
                companyTreeList: [],
                comPanyId: undefined,
                departmentId: undefined,
                //部门树
                DepTreeData: [],
                DepTree: [],
                date: undefined,
                Year: undefined,
                Month: undefined,
                startTime: undefined,
                endTime: undefined,
                filterText: undefined,
                Unshift: false,
                unCheckEmp:false,
                AttGroupId: undefined,
                //日期表头
                dayheaderList: [],
                // 卡片样式
                style: {
                    left: "0px",
                    top: "0px"
                },
                // 卡片遮罩样式
                cardloadStyle: {
                    width: "0px",
                    height: "0px",
                    left: "0px",
                    top: "0px"
                },
                //表格左测选中的dom
                thisDom: undefined,
                isCrde: false,
                //点击类型，表头还是单元格
                clickType: undefined,
                //单元格类型，基本信息还是日期
                columnType: undefined,
                UserShiftPlanList: [],
                thisDombg: undefined,
                tabDisabled: false,
                //周期排班
                shiftStartTime: undefined,
                shiftEndTime: undefined,
                shiftRule: undefined,
                shiftInfoCodeList: [],
                shiftInfoist: [],
                //按天排班
                UserPlan: new UserShiftPlanByCodeInput(),
                userName: undefined,
                //按周期排
                UserShiftPlan: new UserShiftPlanByCycleInput(),
                shiftCodeIndex: undefined,
                tabsTip: "",
                TableData: []
            }
        },
        created() {
            this._DepartmentServiceProxy = new DepartmentServiceProxy(this.$apiUrl, this.$api);
            this._CompanyConfigServiceProxy = new CompanyServiceProxy(this.$apiUrl, this.$api);
            this._attendanceGroupAppService = new AttendanceGroupServiceProxy(this.$apiUrl, this.$api);
            this._fileDownloadService = fileDownloadService;
            this._enterpriseAttendCycleServiceProxy = new EnterpriseAttendCycleServiceProxy(this.$apiUrl, this.$api);
            this._userShiftPlanServiceServiceProxy = new UserShiftPlanServiceServiceProxy(this.$apiUrl, this.$api);

        },
        async mounted() {
            await this.getCycle();
            this.AttGroupList = await this.getAttGroupList();
            this.cardloadStyle.width = document.body.height - 220 + "px";
            this.cardloadStyle.height = document.body.height - 130 + "px";
            this.companyTreeInit();
            if(abpService.abp.userInfo.companyId){
                this.comPanyId=abpService.abp.userInfo.companyId;
                this.handleChange(this.comPanyId);
            }
        },
        methods: {
            searchTree(inputValue, treeNode) {
                return treeNode.data.props.title.includes(inputValue)
            },
            /**
             * 每页条数事件
             */
            showSizeChange(current, pageSize) {
                this.pageSize = pageSize;
                this.pageNumber = current;
                this.startindex = (this.pageNumber - 1) * this.pageSize;
                this.endindex = this.startindex + this.pageSize;
                this.pageData();

            },
            /**
             * 页码事件
             */
            onChange(page, pageSize) {
                this.pageNumber = page;
                this.pageSize = pageSize;

                this.startindex = (page - 1) * this.pageSize;
                this.endindex = this.startindex + this.pageSize;
                this.pageData();
            },
            /**
             * 分页事件
             */
            showTotalFun() {
                return this.l(
                    "GridFooterDisplayText",
                    this.pageNumber,
                    this.totalPages,
                    this.totalItems,
                    this.startindex + 1,
                    this.endindex > this.totalItems ? this.totalItems : this.endindex
                );
            },
            getData() {

                this.spinning = true;
                this._userShiftPlanServiceServiceProxy.getUsreShiftPlanList(
                    this.filterText,
                    this.startTime,
                    this.endTime,
                    this.comPanyId,
                    this.departmentId,
                    this.Unshift,
                    this.AttGroupId,
                    this.unCheckEmp
                )
                    .finally(() => {
                        this.spinning = false;
                    })
                    .then(async res => {
                        let users = res.userList;
                        this.pageNumber = 1;
                        this.startindex = 0;
                        this.endindex = 10;
                        this.TableData = await this.userShiftPlanInit(users, res.getUserShiftPlanList);
                        this.totalItems = this.TableData.length;
                        this.pageData();
                    });
            },
            /**
             * 数据分页
             */
            pageData() {
                this.spinning = true;
                this.totalPages = Math.ceil(
                    this.totalItems / this.pageSize
                );
                this.UserShiftPlanList = _.slice(this.TableData, this.startindex, this.endindex);
                // console.log(this.UserShiftPlanList)
                this.spinning = false;
            },
            async getCycle() {
                let cycle = await this.initCycle();
                this.startTime = cycle.startTime;
                this.endTime = cycle.endTime;
                this.date = cycle.yearOrMonth;
                if (this.startTime && this.endTime) {
                    this.dayheaderList = [];
                    this.headerDaysInit(this.startTime, this.endTime);
                }
            },
            /**
             * 获取考勤组班次
             */
            async shiftInfoList(AttGroupId) {
                return new Promise((resolve, reject) => {
                    if (!AttGroupId) {
                        AttGroupId = undefined;
                    }
                    this.spinning = true;
                    this._attendanceGroupAppService.getShiftInfoCodeByAttGroupId(AttGroupId)
                        .finally(() => {
                            this.spinning = false;
                        })
                        .then(res => {
                            resolve(res);
                        })
                });
            },
            /**
             * 获取考勤周期班次
             */
            async getShiftCycleCodesList(AttGroupId) {
                return new Promise((resolve, reject) => {
                    if (!AttGroupId) {
                        AttGroupId = undefined;
                    }
                    this.spinning = true;
                    this._userShiftPlanServiceServiceProxy.getShiftInfoListByAttGroupId(AttGroupId)
                        .finally(() => {
                            this.spinning = false;
                        })
                        .then(res => {
                            resolve(res);
                        })
                })
            },
            /**
             * 初始化表格数据
             */
            async userShiftPlanInit(users, planList) {
                return new Promise((resolve, reject) => {
                    let shifts = [];
                    users.forEach((user, i) => {
                        let p = {};
                        p.userId = user;
                        let plans = planList.filter(item => item.empId == user);
                        plans.forEach((item, index) => {
                            let s = {};
                            if (index == 0) {
                                p.companyId = item.compId;
                                p.departmentId = item.depId;
                                p.departmentName = item.deptName;
                                p.userName = item.empName;
                                p.attGroupId = item.attGroupId;
                                p.attGroupName = item.attGroupName;
                                p.jobNumber = item.jobNumber;
                                p.hireDate=item.hireDate?moment(item.hireDate).format("YYYY-MM-DD"):"";
                                p.termDate=item.termDate?moment(item.termDate).format("YYYY-MM-DD"):"";
                                s.shiftCode = item.shiftCode;
                                s.userId = user;
                                s.date = item.date;
                                // s.title=moment(item.date).format("YYYY-MM-DD")+(item.shiftName?" 班次:"+item.shiftName:" 未排班")
                                if (item.shiftCode) {
                                    //休息
                                    if (item.shiftCode == "Rest") {
                                        s.shiftName = "休息";
                                        s.userName = item.empName;
                                        s.attGroupId = item.attGroupId;
                                        s.bgcolor = "#87d068";
                                        s.title = moment(item.date).format("YYYY-MM-DD") + " 休息"
                                    } else if (item.shiftCode == "Holiday") {
                                        s.shiftName = "法定节假日";
                                        s.bgcolor = "pink";
                                        s.userName = item.empName;
                                        s.attGroupId = item.attGroupId;
                                        s.title = moment(item.date).format("YYYY-MM-DD") + " 法定节假日"
                                    } else {
                                        s.shiftName = item.shiftName;
                                        s.attGroupId = item.attGroupId;
                                        s.userName = item.empName;
                                        s.title = moment(item.date).format("YYYY-MM-DD") + " 班次:" + item.shiftName;
                                        s.bgcolor = "#108ee9"
                                    }
                                } else {
                                    s.title = moment(item.date).format("YYYY-MM-DD") + " 未排班"
                                    s.attGroupId = item.attGroupId;
                                    s.userName = item.empName;
                                    s.bgcolor = '';
                                }
                                p.planList = [s];
                            } else {
                                s.shiftCode = item.shiftCode;
                                s.userId = user;
                                // s.title=moment(item.date).format("YYYY-MM-DD")+(item.shiftCode?" 班次:"+item.shiftName:" 未排班")
                                if (item.shiftCode) {
                                    if (item.shiftCode == "Rest") {
                                        s.shiftName = "休息";
                                        s.userName = item.empName;
                                        s.attGroupId = item.attGroupId;
                                        s.title = moment(item.date).format("YYYY-MM-DD") + " 休息"
                                        s.bgcolor = "#87d068";
                                    } else if (item.shiftCode == "Holiday") {
                                        s.title = moment(item.date).format("YYYY-MM-DD") + " 法定节假日"
                                        s.shiftName = "法定节假日";
                                        s.userName = item.empName;
                                        s.attGroupId = item.attGroupId;
                                        s.bgcolor = "pink";
                                    } else {
                                        s.title = moment(item.date).format("YYYY-MM-DD") + " 班次:" + item.shiftName;
                                        s.shiftName = item.shiftName;
                                        s.attGroupId = item.attGroupId;
                                        s.userName = item.empName;
                                        s.bgcolor = "#108ee9"
                                    }
                                } else {
                                    s.title = moment(item.date).format("YYYY-MM-DD") + " 未排班"
                                    s.attGroupId = item.attGroupId;
                                    s.userName = item.empName;
                                    s.bgcolor = '';
                                }
                                s.date = item.date;
                                p.planList = [...p.planList, s];
                            }
                        });
                        shifts = [...shifts, p];
                    });
                    resolve(shifts);
                })

            },
            /**
             * 获取考情组列表
             */
            async getAttGroupList() {
                return new Promise((resolve, reject) => {
                    this.spinning = true;
                    this._attendanceGroupAppService.getAttGroupList()
                        .finally(() => {
                            this.spinning = false;
                        })
                        .then(res => {
                            resolve(res);
                        });
                });

            },
            /**
             * 获取周期设置
             */
            async initCycle() {
                return new Promise(((resolve, reject) => {
                    this.spinning = true;
                    this._enterpriseAttendCycleServiceProxy.getCycleByNow()
                        .finally(() => {
                            this.spinning = false
                        })
                        .then(res => {
                            resolve(res);
                        });
                }));

            },
            /**
             * 表头数据初始化
             */
            headerDaysInit(startTime, endTime) {
                let days = moment(endTime).diff(startTime, "day") + 1;
                let week;
                for (let i = 0; i < days; i++) {
                    week = moment(startTime).add(i, 'day').day();
                    this.dayheaderList = [...this.dayheaderList, {
                        day: moment(startTime).add(i, 'day').format('DD'),
                        dayTime: moment(startTime).add(i, 'day').format('YYYY-MM-DD'),
                        week: this.weekInit(week),
                        color: week == 0 || week == 6 ? 'red' : '',
                        title: moment(startTime).add(i, 'day').format('YYYY-MM-DD') + " 周" + this.weekInit(week)
                    }];
                }
            },
            /**
             * 星期数据格式化
             */
            weekInit(number) {
                switch (number) {
                    case 0:
                        return "日"
                        break;
                    case 1:
                        return "一"
                        break;
                    case 2:
                        return "二"
                        break;
                    case 3:
                        return "三"
                        break;
                    case 4:
                        return "四"
                        break;
                    case 5:
                        return "五"
                        break;
                    case 6:
                        return "六"
                        break;
                }
            },
            companyTreeInit() {
                this._CompanyConfigServiceProxy
                    .getPaged(undefined, "", 100, 0)
                    .finally(() => {
                        this.spinning = false;
                    })
                    .then((res) => {
                        this.companyList = res.items;
                        this.companyList.forEach((item) => {
                            if (item.parentId == 0) {
                                var obj = {
                                    key: item.id,
                                    value: item.id,
                                    title: item.fullName
                                };
                                this.companyTreeList.push(obj);

                            }
                        });
                        this.initCompanyTree(this.companyTreeList);
                        if (this.companyTreeList.length > 0&&!this.comPanyId) {
                            this.handleChange(this.companyTreeList[0].key);
                        }

                    });
            },
            /**
             * 初始化树形结构数据格式
             */
            initCompanyTree(list) {
                list.forEach((v) => {
                    //遍历父级数据，加载子集=
                    v.children = []; //创建空的子集
                    this.companyList.forEach((m) => {
                        if (m.parentId == v.value) {
                            //根据主键查子集数据
                            var obj = {
                                key: m.id,
                                value: m.id,
                                title: m.fullName
                            };
                            v.children.push(obj); //放进父级的集合中
                            this.initCompanyTree(v.children); //递归查询子集的集合
                        }
                    });
                    if (v.children.length == 0) {
                        //校验如何数据没有子集，删除子集参数
                        delete v.children;
                    }
                });
            },
            handleChange(value) {
                this.comPanyId = value;
                this.getData();
                this.departmentId = undefined;
                this.getDepTreeData();
            },
            /**
             * 获取部门
             */
            getDepTreeData() {
                this.DepTreeData = [];
                this._DepartmentServiceProxy.getDepTreeData(this.comPanyId).then(res => {
                    this.DepTree =DepTreeUntils.getInstance().InitSelectData(res);
                    this.DepTree.forEach(item => {
                        if (!item.parentId) {
                            this.DepTreeData.push(item);
                        }
                    });
                    this.DepTreeData=DepTreeUntils.getInstance().ArrToTree(this.DepTree,this.DepTreeData,false);

                }).finally(() => {
                    this.loading = false;
                })
            },
            /**
             * 选择部门
             */
            SelectDepartmentTree(e) {
                this.departmentId = e;
            },
            /**
             * 月份选择change
             */
            async monthChange(date) {
                this.Year = moment(date).year();
                this.Month = moment(date).month() + 1;
                let cycle = await this.getCycleBydate();
                this.startTime = cycle.startTime;
                this.endTime = cycle.endTime;
                if (this.startTime && this.endTime) {
                    this.dayheaderList = [];
                    this.headerDaysInit(this.startTime, this.endTime);
                }
                this.getData();
            },
            /**
             * 获取指定年月的周期
             */
            async getCycleBydate() {
                return new Promise((resolve, reject) => {
                    this.spinning = true;
                    this._enterpriseAttendCycleServiceProxy.getByTenant(this.Year, this.Month)
                        .finally(() => {
                            this.spinning = false
                        })
                        .then(res => {
                            resolve(res);
                        })
                })
            },
            /**
             * 卡片遮罩
             * @param e
             * @param a
             */
            cardClick(e, a) {
                //单元格
                if (this.clickType == 0) {
                    //日期
                    if (this.columnType == 0) {
                        this.thisDom.style.backgroundColor = this.bgColor;
                        this.thisDom.style.border = "";
                        this.thisDom.style.color = "";
                    }
                    //基本信息
                    else {
                        this.thisDom.toElement.style.backgroundColor = this.bgColor;
                        this.thisDom.toElement.style.border = "";
                        this.thisDom.toElement.style.color = "";
                    }

                }
                //表头
                else {
                    this.thisDom.style.border = "";
                }
                //显示整体的滚动体
                // $('.layout-content').css("overflow", "auto");
                this.shiftCodeIndex = undefined;
                this.isCrde = false;
            },
            /**
             * 单元格点击事件
             * @param record
             * @param index
             */
            async columnClick(record, index, type, row) {
                if (!row.attGroupId) {
                    this.tabDisabled = true;
                } else {
                    this.tabDisabled = false;
                }
                //按天排班的参数
                this.UserPlan.userId = row.userId;
                this.UserPlan.date = row.date;
                this.userName = row.userName;
                //按周期排班参数
                this.UserShiftPlan.userId = row.userId;
                this.UserShiftPlan.attGroupId = row.attGroupId;
                //隐藏滚动条，防止控件异常
                // $('.layout-content').css("overflow", "hidden");
                this.shiftInfoCodeList = await this.shiftInfoList(row.attGroupId);
                this.shiftInfoist = await this.getShiftCycleCodesList(row.attGroupId);
                if (this.shiftInfoCodeList.length > 0) {
                    this.shiftInfoCodeList = [...this.shiftInfoCodeList,
                        ...[
                            {
                                code: "Rest",
                                name: "休息"
                            }
                            , {
                                code: "Holiday",
                                name: "法定节假日"
                            }
                            , {
                                code: "Empty",
                                name: "清空"
                            }
                        ]
                    ]
                }
                let cycle = await this.initCycle();
                this.shiftStartTime = cycle.startTime;
                this.shiftEndTime = cycle.endTime;
                this.clickType = 0;
                // 右边日期
                if (type == 0) {
                    //按天排班的参数
                    this.UserPlan.type = 2;
                    this.tabsTip = "修改员工" + this.userName + "当天班次";
                    this.columnType = 0;
                    this.thisDom = record.target.parentElement;
                    this.bgColor = this.thisDom.style.backgroundColor;
                    this.thisDom.style.backgroundColor = "#FB6370";
                    this.thisDom.style.border = "1px solid #ffffff";
                    this.thisDom.style.color = "#ffffff";

                }
                //左边员工信息
                else {
                    //按天排班的参数
                    this.UserPlan.type = 1;
                    this.tabsTip = "修改员工" + this.userName + "当前周期班次";
                    this.columnType = 1;
                    this.thisDom = record;
                    this.bgColor = this.thisDom.target.style.backgroundColor;
                    this.thisDom.toElement.style.backgroundColor = "#EFF9FF";
                    this.thisDom.toElement.style.border = "1px solid #1890FF";
                    this.thisDom.toElement.style.color = "#1890FF";
                }


                this.isCrde = true;
                this.style.left = (record.x - 270) + "px";
                //(基础高度+表头高度)+((行号索引+1)*行高度)+偏移量
                this.style.top = (195 + 0) + ((index + 1) * 52) + 3 + "px";

            },
            /**
             * 日期表头单元格点击事件
             * @param record
             */
            async headerColumnClick(record) {
                if (!this.AttGroupId) {
                    return abp.message.warn("请选择考勤组")
                }
                this.shiftInfoCodeList = await this.shiftInfoList();
                if (this.shiftInfoCodeList.length > 0) {
                    this.shiftInfoCodeList = [...this.shiftInfoCodeList, ...[{code: "Rest", name: "休息"}, {
                        code: "Empty",
                        name: "清空"
                    }]]
                }

                this.clickType = 1;
                this.thisDom = record.target.parentElement;
                this.thisDom.style.border = "2px solid red";
                this.isCrde = true;
                this.style.left = (record.x - 270) + "px";
                //245基础top，3偏移量
                this.style.top = (245 + 3 + 52) + "px";
                this.tabDisabled = true;
            },
            /**
             * 按天排班
             * @param item
             * @param index
             */
            saveUserPlan(item, index) {

                this.UserPlan.startTime = moment(this.startTime);
                this.UserPlan.endTime = moment(this.endTime);
                let content = "";
                //清空
                if (item.code == "Empty") {
                    if (this.UserPlan.type == 2) {
                        content = "您确认要清空" + moment(this.UserPlan.date).format("YYYY-MM-DD") + "的排班计划吗？";
                    } else {
                        content = "您确认要清空" + this.userName + "这个周期的排班计划吗？"
                    }
                    this.$confirm({
                        title: "确认信息",
                        content: content,
                        okText: "确认",
                        cancelText: "取消",
                        onOk: () => {
                            this.spinning = true;
                            this.UserPlan.date = moment(this.UserPlan.date)
                            this._userShiftPlanServiceServiceProxy.emptyPlan(this.UserPlan)
                                .finally(() => {
                                    this.spinning = false;
                                })
                                .then(res => {
                                    this.$notification["success"]({
                                        message: this.l("SavedSuccessfully"),
                                    });
                                    this.cardClick();
                                    this.getData();
                                })
                        }
                    });
                }
                else {
                    if (this.UserPlan.type == 2) {
                        content = "您确认要更改" + moment(this.UserPlan.date).format("YYYY-MM-DD") + "排班为" + item.name + "吗？";
                    } else {
                        content = "您确认要更改" + this.userName + "这个周期的排班计划吗？"
                    }
                    this.$confirm({
                        title: "确认信息",
                        content: content,
                        okText: "确认",
                        cancelText: "取消",
                        onOk: () => {
                            this.UserPlan.shiftCode = item.code;
                            this.spinning = true;
                            this.UserPlan.date = moment(this.UserPlan.date);
                            this._userShiftPlanServiceServiceProxy.updateUserShiftCode(this.UserPlan)
                                .finally(() => {
                                    this.spinning = false
                                })
                                .then(res => {
                                    this.$notification["success"]({
                                        message: this.l("SavedSuccessfully"),
                                    });
                                    this.cardClick();
                                    this.getData();
                                });
                        }
                    });

                }


            },
            /**
             * 按周期排班
             * @returns {any}
             */
            saveUserShiftPlan() {
                if (!this.shiftStartTime) {
                    return abp.message.warn("请选择开始日期")
                }
                if (!this.shiftEndTime) {
                    return abp.message.warn("请选择结束日期")
                }
                if (this.shiftCodeIndex != 0) {
                    if (!this.shiftCodeIndex) {
                        return abp.message.warn("请选择开始班次");
                    }
                }

                if (this.UserShiftPlan.endTime < this.UserShiftPlan.startTime) {
                    return abp.message.warn("结束日期不能小于开始日期")
                }
                this.UserShiftPlan.startTime = moment(this.shiftStartTime);
                this.UserShiftPlan.endTime = moment(this.shiftEndTime);
                this.UserShiftPlan.shiftCodeIndex = this.shiftCodeIndex;
                if (!this.shiftRule) {
                    this.shiftRule = 1;
                }
                this.UserShiftPlan.ruleCode = this.shiftRule;
                this.spinning = true;
                this._userShiftPlanServiceServiceProxy.updateUserShiftPlanByCycle(this.UserShiftPlan)
                    .finally(() => {
                        this.spinning = false
                    })
                    .then(res => {
                        this.$notification["success"]({
                            message: this.l("SavedSuccessfully"),
                        });
                        this.shiftRule = undefined;
                        this.shiftCodeIndex = undefined;
                        this.cardClick();
                        this.getData();
                    })
            },
            /**
             * 导入排班
             * @constructor
             */
            Export() {
                ModalHelper.create(
                    ImportExcelModel,
                    {
                        startTime: this.startTime,
                        endTime: this.endTime
                    },
                    {
                        width: "800px"
                    }
                ).subscribe(res => {
                    this.getData();
                });
            },
            shiftChange(){
                ModalHelper.create(
                    ShiftChange,
                    {},
                    {
                        width:"600px"
                    }
                )
                    .subscribe(res=>{
                    if(res){
                        this.getData();
                    }
                })
            },
            scrollTableBody(e) {
                this.$refs.tableHead.scrollLeft = e.target.scrollLeft
            },
            scrollTableHead(e) {
                this.$refs.tableBody.scrollLeft = e.target.scrollLeft
            },
        }
    }
</script>

<style scoped lang="less">
    .pagination {
        margin: 10px auto;
        text-align: right;
    }

    @import "./user-attendance-plan";

</style>
